aside#sidebar.main-sidebar {
    height: 100vh;
}

.brand-link .brand-image {
    margin-left: 0;
}

#sidebar, #sidebar .sidebar, #sidebar .brand-link {
    background: #000;
}

#sidebar li.nav-item{
    line-height: 40px;
}

#sidebar li.nav-item a {
    display: block;
    -webkit-transition-duration: .4s;
    -moz-transition-duration: .4s;
    transition-duration: .4s;
}

#sidebar li.nav-item a .nav-icon {
    margin: 0 17px;
}

#sidebar li.nav-item a:hover {
    color: #000;
    background: #c2c7d0;
}

#sidebar li.nav-item p {
    display: inline;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #f57f18;
}

h2 {
  padding: 20px 15px;
  border-bottom: 3px solid #000;
  margin-bottom: 25px;
}

.hidden {
  display: none;
}

.btn-primary {
  background-color: #000;
  border-color: #000;
}

.btn-primary:hover {
  background-color: #f57f18;
  border-color: #f57f18;
}

button.ico-button span {
  display: inline-block;
  margin-left: 5px;
  -webkit-transform: translate(0,-2px);
  -moz-transform: translate(0,-2px);
  transform: translate(0,-2px);
}

button.table-button {
  background: none;
  border: 0;
  font-size: 20px;
}

button.table-button.trash {
  color: #FE0000;
}

.actionBar {
  margin-bottom: 20px;
}

.bottoniera {
  text-align: center;
  padding: 35px 0;
}

.bottoniera .row {
  justify-content: center;
}

.errors {
  color: #FE0000;
  font-weight: bold;
  margin-bottom: 35px;
}

.tac {
  text-align: center;
}